














// Core
import { Component, Mixins } from 'vue-property-decorator'

// Mixins
import GetStoreMixin from '@/mixins/GetStore'

// Store
import { userNamespace } from '@store/user'

// Types
import { UserActionTypes } from '@store/user/Types'

// Interfaces
import { IUser } from '@store/user/Interface'
import { Project } from '@/store/projects/interfaces'

@Component({
  name: 'Logout',
})
export default class LogoutComponent extends Mixins(GetStoreMixin) {
  @userNamespace.State('user') private user!: IUser

  @userNamespace.Action(UserActionTypes.A_LOGOUT) private logout!: () => void

  private get projectName(): string {
    return this.user.projects.find(p => p.id === this.selectedProject)?.name ?? ''
  }

  private userLogout() {
    this.$router.push({ name: 'auth' })
    this.logout()

    this.$destroy()
  }
}
